import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import 'bootstrap-icons/font/bootstrap-icons.css';
import GoalInfo from './GoalInfo.js';
import AllIndicatorsForGoalList from './AllIndicatorsForGoalList.js';
import IndicatorDetails from './IndicatorDetails.js';
import Skeleton from '@mui/material/Skeleton';
import { GoalsContext } from '../State/GoalsContext.ts';
import { IndicatorsContext } from '../State/IndicatorsContext.ts';

const AllGoalIndicators = () => {

  
  const { selectedGoal, fetchGoal, loading: goalLoading } = useContext(GoalsContext);
  const { indicators, loading: indicatorsLoading, fetchAllIndicators } = useContext(IndicatorsContext);

  const { goalId, indicatorId } = useParams();
  const [selectedIndicatorId, setSelectedIndicatorId] = useState(indicatorId || null);
  const [selectedIndicator, setSelectedIndicator] = useState(indicators.find(i => i.id === parseInt(indicatorId)) || null);
  const [showSheet, setShowSheet] = useState(false);

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  // handle selected target
  useEffect(() => {
    if (indicatorId) {
      setSelectedIndicatorId(indicatorId);
      setSelectedIndicator(indicators.find(i => i.id === parseInt(indicatorId)));
    }
  }, [indicatorId, indicators]);

  // show for mobile 
  useEffect(() => {
    if (isMobile && selectedIndicatorId) {
      setShowSheet(true);
    }
  }, [isMobile, selectedIndicatorId]);

  const handleCloseSheet = () => setShowSheet(false);

  // Check for selected goal 
  useEffect(() => {

    if (!selectedGoal || selectedGoal.id !== parseInt(goalId)) {
      fetchGoal(parseInt(goalId));
    }

    // fetch targets
    if (selectedGoal) {
      fetchAllIndicators(selectedGoal.id);
    }

  }, [goalId, selectedGoal]);
  
  if (goalLoading || indicatorsLoading ) return <div >
        <Skeleton variant="text" width={'100%'} height={5} />
    </div>;

  if (!selectedGoal) return <p>Goal not found</p>;

  return (
    <div className='container-full'>
      <Container fluid className='goal-details-container'>
        <Row style={{ height: '91%' }}>
          <Col xs={12} md={4} className="targets-column">
            <GoalInfo goal={selectedGoal} />
            <Row className="targets-list">
            <div className='section-title'>
              <h6>INDICATORS</h6>
            </div>
              <AllIndicatorsForGoalList
                indicators={indicators} // Pass targets here
                goalId={goalId}
                activeIndicatorId={selectedIndicatorId}
                onIndicatorSelect={setSelectedIndicatorId}
              />
            </Row>
          </Col>

          {/* todo: uncomment  */}
          {/* {!isMobile && (
            <Col xs={12} md={8} className='indicators-column'>
              <IndicatorDetails
                selectedTarget={selectedTarget}
                selectedTargetId={selectedTargetId}
              />
            </Col>
          )} */}
        </Row>
          {/* uncomment bellow */}
        {/* Bottom sheet for mobile view */}
        {/* {isMobile && showSheet && (
          <div className="bottom-sheet">
            <div className="bottom-sheet-header">
              <Button variant="secondary" onClick={handleCloseSheet}>
                Close
              </Button>
            </div>
            <div className="bottom-sheet-content">
              <IndicatorDetails
                selectedTarget={selectedTarget}
                selectedTargetId={selectedTargetId}
              />
            </div>
          </div>
        )} */}
      </Container>
    </div>
  );
};

export default AllGoalIndicators;
