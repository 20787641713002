import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { StatusIndicator } from './StatusIndicator';


const AllIndicatorsForGoalList = ({ indicators, goalId, activeIndicatorId, onIndicatorSelect }) => {
  const { indicatorId } = useParams(); // Get targetId from URL parameters

  const handleIndicatorClick = (indicatorId) => {
    onIndicatorSelect(indicatorId); // Update selected target ID in parent component
  };

  return (
    <ListGroup>
      {indicators.map(indicator => (
        
        <Link
          key={indicator.id}
          to={`/goal/${goalId}/indicators/${indicator.id}`} // Link to target with goalId and targetId in URL
          className={`list-group-item list-group-item-action ${indicator.id.toString() === indicatorId ? 'active' : ''}`}
          onClick={() => handleIndicatorClick(indicator.id)} // Call handleIndicatorClick when target is clicked
        >
          
          <StatusIndicator status={indicator.acf.status}></StatusIndicator>

          <strong>{indicator.acf.index}</strong> {indicator.acf.description}
        </Link>
      ))}
    </ListGroup>
  );
};

export default AllIndicatorsForGoalList;


